<template>
  <v-card elevation="3" min-height="500" class="pay-page rounded-10">
    <v-card-title>
      <h1 class="primary--text font-weight-bold">Pay</h1>
      <v-spacer></v-spacer>
      <v-btn
        class="text-none px-5"
        depressed
        color="primary"
        @click="$router.go(-1)"
      >
        Back
      </v-btn>
    </v-card-title>
    <v-card-text class="px-5">
      <v-row dense align="stretch">
        <v-col order-sm="2" order-md="1" class="pr-md-10">
          <h3 class="title primary--text font-weight-bold">Summary</h3>
          {{ mainSelected }}
          <v-list dense subheader>
            <template v-if="mainSelected">
              <v-subheader class="primary--text fs-18">
                Main Subscription
              </v-subheader>
              <v-list-item :key="mainSelected.id">
                <v-list-item-title class="fs-15 primary--text">
                  {{ mainSelected.name }} Subscription
                </v-list-item-title>
                <v-list-item-action>
                  <v-chip style="min-width: 90px" label outlined>
                    {{ mainSelected.plan_amount | money }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </template>
            <template v-if="addons_selected.length">
              <v-subheader class="primary--text fs-18">
                Addons Subscriptions
              </v-subheader>
              <template v-for="item in addons_selected">
                <v-list-item :key="item.id">
                  <v-list-item-title class="fs-15 primary--text">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-chip class="text-center" style="min-width: 130px" label>
                      {{ item.price | money }} |
                      <span class="ml-1 overline"> Month </span>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </template>
            <v-divider></v-divider>
            <v-list-item :key="99999">
              <v-list-item-subtitle class="font-weight-bold">
                Total
              </v-list-item-subtitle>
              <v-list-item-action>
                <v-chip class="text-center" style="min-width: 130px" label>
                  {{ totals | money }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col v-if="!payment_method" md="6" sm="12" order-sm="1" order-md="2">
          <v-form v-model="valid">
            <label>Payee Details</label>
            <v-row dense>
              <v-col md="6" sm="12">
                <label class="primary--text subtitle-2">First name</label>
                <v-text-field
                  v-model="customer.first_name"
                  dense
                  outlined
                  hide-details="auto"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="12">
                <label class="primary--text subtitle-2">Last name</label>
                <v-text-field
                  v-model="customer.last_name"
                  dense
                  outlined
                  hide-details="auto"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col md="6" sm="12">
                <label class="primary--text subtitle-2">Email</label>
                <v-text-field
                  v-model="customer.email"
                  dense
                  outlined
                  hide-details="auto"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="12">
                <label class="primary--text subtitle-2">Address</label>
                <v-text-field
                  v-model="customer.address"
                  dense
                  outlined
                  hide-details="auto"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col md="6" sm="12">
                <label class="primary--text subtitle-2">City</label>
                <v-text-field
                  v-model="customer.city"
                  dense
                  outlined
                  hide-details="auto"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col md="3" sm="12">
                <label class="primary--text subtitle-2">State</label>
                <v-text-field
                  v-model="customer.state"
                  dense
                  outlined
                  hide-details="auto"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col md="3" sm="12">
                <label class="primary--text subtitle-2">Postal Code</label>
                <v-text-field
                  v-model="customer.zip_code"
                  dense
                  outlined
                  hide-details="auto"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col md="12">
                <label for="card-element" class="primary--text subtitle-2">
                  Card Details
                </label>
                <div id="card-element"></div>
              </v-col>
            </v-row>
            <v-btn
              block
              :disabled="btn_disabled"
              :loading="processing"
              color="primary"
              @click="processPayment"
              large
              class="text-none my-2"
            >
              Pay {{ totals | money }}
            </v-btn>
            <a
              class="caption"
              href="https://stripe.com/"
              style="text-decoration: none; color: grey"
              target="_blank"
            >
              Powered by <b>Stripe</b>
            </a>
          </v-form>
        </v-col>
        <v-col v-else md="6" sm="12" order-sm="1" order-md="2">
          <v-row dense>
            <v-col md="12">
              <h5 class="mb-1 subtitle-1">Card Details</h5>
              <v-text-field
                filled
                hide-details="auto"
                class="mb-1"
                label="Email"
                readonly
                :value="payment_method.user.email"
              ></v-text-field>
              <v-text-field
                filled
                hide-details="auto"
                class="mb-1"
                label="Card Brand"
                readonly
                :value="payment_method.card.brand"
              ></v-text-field>
              <v-text-field
                filled
                hide-details="auto"
                class="mb-1"
                label="Card Last 4"
                readonly
                :value="`**** **** **** ${payment_method.card.last4}`"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            block
            :disabled="processing || totals < 1"
            :loading="processing"
            color="primary"
            @click="processPaymentAddons"
            large
            class="text-none my-2"
          >
            Pay {{ totals | money }}
          </v-btn>
          <a
            class="caption"
            href="https://stripe.com/"
            style="text-decoration: none; color: grey"
            target="_blank"
          >
            Powered by <b>Stripe</b>
          </a>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import _map from "lodash/map";
import { mapGetters, mapMutations } from "vuex";
import request from "@/services/axios_instance";
import { settings } from "@/variables";

export default {
  name: "Pay",
  data: () => ({
    valid: false,
    stripe: {},
    cardElement: {},
    customer: {
      first_name: null,
      last_name: null,
      email: null,
      address: null,
      city: null,
      state: null,
      zip_code: null,
    },
    processing: false,
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("subscriptionv2", [
      "mainSelected",
      "totals",
      "paymentMethod",
    ]),
    ...mapGetters("subscription", [
      "main_selected",
      "addons_selected",
      "payment_method",
    ]),
    cart() {
      return {
        main: this.mainSelected,
        addons: this.addons_selected,
      };
    },
    btn_disabled() {
      return this.totals < 1 || this.processing || !this.valid;
    },
  },
  async mounted() {
    if (!this.payment_method) {
      this.stripe = await loadStripe(settings.stripe.pub_key);
      const element = this.stripe.elements();
      this.cardElement = element.create("card", {
        classes: {
          base: "py-5",
        },
      });
      this.cardElement.mount("#card-element");
    }
    this.mapUser();
  },
  methods: {
    ...mapMutations("subscription", [
      "clearAllSelected",
      "setMainSubscription",
      "setAddonsSubscriptions",
    ]),
    mapUser() {
      this.customer = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        address: this.user.props.location || "",
        city: this.user.props.city || "",
        state: this.user.props.state || "",
        zip_code: this.user.props.zip_code || "",
      };
    },
    async processPayment() {
      this.processing = true;
      const { paymentMethod, error } = await this.stripe.createPaymentMethod(
        "card",
        this.cardElement,
        {
          billing_details: {
            name: this.customer.first_name + " " + this.customer.last_name,
            email: this.customer.email,
            address: {
              line1: this.customer.address,
              city: this.customer.city,
              state: this.customer.state,
              postal_code: this.customer.zip_code,
            },
          },
        }
      );
      if (error) {
        this.processing = false;
        this.appSnackbar( "error", "error");
      } else {
        this.customer.payment_method_id = paymentMethod.id;
        this.customer.amount = this.totals;
        this.customer.cart = this.cart;
        this.processing = true;
        request
          .post(`api/subscriptions/purchase`, this.customer)
          .then(({ data }) => {
            this.clearAllSelected();
            this.setMainSubscription(data.main);
            this.setAddonsSubscriptions(data.addons);
            this.processing = false;
            this.$router.push({ name: "subscriptions_summary" });
          })
          .catch((error) => {
            this.processing = false;
            this.appSnackbar( error, "error");
          });
      }
    },
    processPaymentAddons() {
      this.processing = true;
      let payload = {
        addons: _map(this.addons_selected, "id"),
      };
      request
        .put(`api/subscriptions/purchase`, payload)
        .then(({ data }) => {
          this.clearAllSelected();
          this.setMainSubscription(data.main);
          this.setAddonsSubscriptions(data.addons);
          this.processing = false;
          this.$router.push({ name: "subscriptions_summary" });
        })
        .catch((error) => {
          this.processing = false;
          this.appSnackbar( error, "error");
        });
    },
  },
};
</script>

<style scoped lang="scss">
 
</style>